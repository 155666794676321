<script setup lang="ts">
const props = defineProps<{
  id: string,
  gradient?: boolean
}>()
const gradientId = computed(() => `svgGradientRenewable-${props.id}`)
</script>

<template>
  <svg width="100%" height="100%" viewBox="0 0 63 63" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient :id="gradientId">
        <stop offset="0%" style="stop-color:#2FA766" />
        <stop offset="100%" style="stop-color:#90BC1F" />
      </linearGradient>
    </defs>
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M31.8287 0.11377C48.9948 0.11377 62.9185 14.0374 62.9185 31.2036C62.9185 48.3697 48.9948 62.2934 31.8287 62.2934C14.6625 62.2934 0.738892 48.3697 0.738892 31.2036C0.738892 14.0374 14.6625 0.11377 31.8287 0.11377ZM15.4851 33.8977C15.1513 34.1242 15.3182 34.5653 15.7354 34.5057C19.0256 33.9215 22.4231 34.3865 25.7371 34.0646C26.6788 33.9692 27.5729 33.4089 28.1093 32.6579C28.908 31.5493 29.9571 29.0578 30.8631 28.271L29.9929 53.7937C29.9929 55.0573 33.6883 55.0573 33.6883 53.7937L32.8181 28.271C33.7241 29.0578 34.7612 31.585 35.5719 32.6579C36.1679 33.4089 37.0024 33.9454 37.956 34.0646C40.6025 34.3865 44.8225 33.9573 47.9458 34.5057C48.3511 34.5772 48.5299 34.1242 48.1961 33.8977C45.0132 31.9188 39.1005 29.1055 33.3307 27.222C32.9612 27.1028 32.7943 26.912 32.7704 26.5067L32.7466 25.5888C32.7108 23.4192 32.532 20.7251 32.3771 18.4958C32.1029 14.5977 31.5783 10.9737 31.0538 8.58955C30.9585 8.19616 30.4816 8.20808 30.3982 8.62532C29.8856 11.7486 28.0378 16.0878 27.406 18.7343C27.1914 19.6641 27.2749 20.6535 27.9425 21.4403C28.8008 22.4655 31.0181 24.206 30.9585 25.6007L30.9227 26.5186C30.8989 26.8763 30.72 27.1147 30.3624 27.2339C24.6046 29.1174 18.6799 31.9307 15.497 33.9096L15.4851 33.8977Z"
      :fill="gradient ? `url(#${gradientId})` : 'currentColor'" />
  </svg>
</template>