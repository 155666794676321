<script setup lang="ts">
import { Category, type CategoryType } from '~/types'
import Energy from "./Energy.vue"
import Insurance from "./Insurance.vue"
import Telco from "./Telco.vue"
import Electricity from "./Electricity.vue"
import Gas from "./Gas.vue"
import Mobile from "./Mobile.vue"
import RCAuto from "./RCAuto.vue"
import Renewable from "./Renewable.vue"
import SmartHome from "./SmartHome.vue"

const props = withDefaults(
  defineProps<{
    id: string,
    label: string,
    icon: CategoryType,
    size?: number,
    gradient?: boolean
    active?: boolean
    inverted?: boolean
  }>(),
  { size: 10 }
)

const inactiveColor = computed(() => props.inverted ? 'text-primary' : 'text-white')
const activeColor = computed(() => props.inverted ? 'text-white' : 'text-primary')
const isGradient = computed(() => props.inverted ? props.gradient : props.active && props.gradient)
</script>

<template>
  <div class="category">
    <div class="category__icon focus:outline-none" :class="{
      'category--active': active,
      [`h-${size} w-${size}`]: true,
      [`${activeColor}`]: active,
      [`${inactiveColor} hover:${activeColor}/90`]: !active,
    }">
      <Energy v-if="icon === Category.ENERGY" :id="'foo'" :gradient="isGradient" />
      <Insurance v-if="icon === Category.INSURANCE" :id="id" :gradient="isGradient" />
      <Telco v-if="icon === Category.TELCO" :id="id" :gradient="isGradient" />
      <Electricity v-if="icon === Category.ELECTRICITY" :id="id" :gradient="isGradient" />
      <Gas v-if="icon === Category.GAS" :id="id" :gradient="isGradient" />
      <Mobile v-if="icon === Category.MOBILE" :id="id" :gradient="isGradient" />
      <RCAuto v-if="icon === Category.RC_AUTO" :id="id" :gradient="isGradient" />
      <Renewable v-if="icon === Category.RENEWABLE" :id="id" :gradient="isGradient" />
      <SmartHome v-if="icon === Category.SMART_HOME" :id="id" :gradient="isGradient" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .category--active {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 1px;
      right: 1px;
      bottom: 1px;
      left: 1px;
      background-color: white;
      z-index: -1;
      border-radius: 100%;
    }
  }
}
</style>