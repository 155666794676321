<script setup lang="ts">
const props = defineProps<{
  id: string,
  gradient?: boolean
}>()

const gradientId = computed(() => `svgGradientGas-${props.id}`)
</script>

<template>
  <svg width="100%" height="100%" viewBox="0 0 63 63" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient :id="gradientId">
        <stop offset="0%" style="stop-color:#3C7DBA" />
        <stop offset="100%" style="stop-color:#004689" />
      </linearGradient>
    </defs>
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M31.4474 0C48.8162 0 62.8948 14.0786 62.8948 31.4474C62.8948 48.8162 48.8162 62.8948 31.4474 62.8948C14.0786 62.8948 0 48.8162 0 31.4474C0 14.0786 14.0786 0 31.4474 0ZM27.2036 49.9964C24.4379 46.7301 21.3146 42.9988 21.1954 39.2437C20.9808 32.6872 26.8817 26.834 31.4474 22.7571C36.0131 26.834 41.914 32.6872 41.6994 39.2437C41.5802 42.9988 38.4569 46.7301 35.6913 49.9964C39.7086 46.3247 45.1207 42.1405 45.3115 36.2873C45.5976 27.4181 37.6105 19.4907 31.4355 13.9833C25.2605 19.4907 17.2734 27.4181 17.5595 36.2873C17.7503 42.1405 23.1743 46.3128 27.1797 49.9964H27.2036ZM31.4474 51.3911C31.5428 48.8043 37.9205 44.5008 38.0635 40.1497C38.1947 35.9178 34.3919 32.1388 31.4474 29.5162C28.503 32.1388 24.7002 35.9178 24.8313 40.1497C24.9744 44.5008 31.3521 48.8043 31.4474 51.3911Z"
      :fill="gradient ? `url(#${gradientId})` : 'currentColor'" />
  </svg>
</template>