<script setup lang="ts">
const props = defineProps<{
  id: string,
  gradient?: boolean
}>()

const gradientId = computed(() => `svgGradientInsurance-${props.id}`)
</script>

<template>
  <svg width="100%" height="100%" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient :id="gradientId">
        <stop offset="0%" style="stop-color:#E9434E" />
        <stop offset="100%" style="stop-color:#B22D47" />
      </linearGradient>
    </defs>
    <g>
      <path
        d="M6.87276 6.79558V12.2127C6.87276 14.3493 8.05302 15.923 11.5 18.3032C11.5015 18.3032 11.503 18.3001 11.5045 18.3001V4.81335C11.5045 4.81335 11.5015 4.81032 11.5 4.80881C9.9596 6.01782 8.58716 6.60947 6.87276 6.79558Z"
        :fill="gradient ? `url(#${gradientId})` : 'currentColor'" />
      <path
        d="M11.5 0C5.14928 0 0 5.14928 0 11.5C0 17.8507 5.14928 23 11.5 23C17.8507 23 23 17.8507 23 11.5C23 5.14928 17.8507 0 11.5 0ZM17.477 5.84987V12.2127C17.477 12.3111 17.4755 12.4079 17.4709 12.5047C17.3438 15.4342 15.292 17.3862 11.7814 19.7452C11.7043 19.7966 11.6029 19.8239 11.5015 19.8239C11.4001 19.8239 11.2988 19.7982 11.2216 19.7452C7.59454 17.3075 5.52454 15.3071 5.52454 12.2127V5.84987C5.52454 5.66375 5.67586 5.51243 5.86197 5.50941C7.88961 5.4625 9.28474 4.9677 11.2488 3.26993C11.3199 3.20941 11.4107 3.17914 11.5015 3.17914C11.5923 3.17914 11.6846 3.20941 11.7542 3.27145C13.7183 4.96921 15.1119 5.46401 17.1411 5.51092C17.1547 5.51092 17.1698 5.51395 17.1834 5.51546C17.2803 5.51546 17.3786 5.51546 17.4785 5.51546V5.85289L17.477 5.84987Z"
        :fill="gradient ? `url(#${gradientId})` : 'currentColor'" />
    </g>
  </svg>
</template>