<script setup lang="ts">
const props = defineProps<{
  id: string,
  gradient?: boolean
}>()
const gradientId = computed(() => `svgGradientRCAuto-${props.id}`)
</script>

<template>
  <svg width="100%" height="100%" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient :id="gradientId">
        <stop offset="0%" style="stop-color:#E9434E" />
        <stop offset="100%" style="stop-color:#B22D47" />
      </linearGradient>
    </defs>
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M15.3493 11.6328C15.1387 11.6824 14.9901 11.8682 14.9901 12.0788C14.9901 12.3266 15.1883 12.5372 15.4484 12.5372H17.443C17.6908 12.5372 17.9014 12.3266 17.9014 12.0788V11.6452C17.9014 11.3603 17.6288 11.1373 17.3439 11.1992L15.3493 11.6328Z"
      :fill="gradient ? `url(#${gradientId})` : 'currentColor'" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M7.71809 11.6328L5.72354 11.1992C5.43861 11.1373 5.16606 11.3603 5.16606 11.6452V12.0788C5.16606 12.3266 5.37667 12.5372 5.62444 12.5372H7.61899C7.86676 12.5372 8.07736 12.3266 8.07736 12.0788C8.07736 11.8682 7.9287 11.6824 7.71809 11.6328Z"
      :fill="gradient ? `url(#${gradientId})` : 'currentColor'" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M15.6838 7.61895C15.5723 7.29685 15.275 7.07385 14.9405 7.07385H8.13924C7.79237 7.07385 7.49504 7.29685 7.39593 7.61895L6.80128 9.44006H16.2785L15.6838 7.61895Z"
      :fill="gradient ? `url(#${gradientId})` : 'currentColor'" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M11.5337 0C5.16601 0 0 5.16601 0 11.5461C0 17.9262 5.16601 23.0798 11.5337 23.0798C17.9014 23.0798 23.0674 17.9138 23.0674 11.5461C23.0674 5.17839 17.9014 0.0123888 11.5337 0.0123888V0ZM18.8058 9.50199L17.6784 9.89842L18.5704 10.6913C18.7686 10.8647 18.8925 11.1249 18.8925 11.3974V15.6838C18.8925 15.9192 18.6943 16.1174 18.4589 16.1174H16.7369C16.5015 16.1174 16.3033 15.9192 16.3033 15.6838V14.8167H6.7889V15.6838C6.7889 15.9192 6.59069 16.1174 6.3553 16.1174H4.6333C4.39792 16.1174 4.1997 15.9192 4.1997 15.6838V11.3974C4.1997 11.1249 4.3112 10.8771 4.52181 10.6913L5.41378 9.89842L4.28642 9.50199C4.05104 9.41527 3.93955 9.15511 4.02626 8.93211L4.13776 8.67196C4.1997 8.51091 4.36075 8.4118 4.53419 8.4118H6.24381L6.76412 6.81368C6.87562 6.45441 7.21011 6.21903 7.59415 6.21903H15.5228C15.8945 6.21903 16.2289 6.4668 16.3528 6.81368L16.8731 8.4118H18.5828C18.7562 8.4118 18.9173 8.51091 18.9792 8.67196L19.0907 8.93211C19.1898 9.15511 19.0659 9.42766 18.8305 9.50199H18.8058Z"
      :fill="gradient ? `url(#${gradientId})` : 'currentColor'" />
  </svg>
</template>