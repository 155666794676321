<script setup lang="ts">
const props = defineProps<{
  id: string,
  gradient?: boolean
}>()

const gradientId = computed(() => `svgGradientMobile-${props.id}`)
</script>

<template>
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient :id="gradientId">
        <stop offset="0%" style="stop-color:#C2E2ED" />
        <stop offset="100%" style="stop-color:#36A4DC" />
      </linearGradient>
    </defs>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5105 6.82617H7.73047V16.9476H15.5105V6.82617Z"
      :fill="gradient ? `url(#${gradientId})` : 'currentColor'" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M11.6204 17.5298C11.1868 17.5298 10.84 17.8767 10.84 18.3103C10.84 18.7439 11.1868 19.0907 11.6204 19.0907C12.054 19.0907 12.4009 18.7439 12.4009 18.3103C12.4009 17.8767 12.054 17.5298 11.6204 17.5298Z"
      :fill="gradient ? `url(#${gradientId})` : 'currentColor'" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M10.679 5.69879H12.5497C12.6735 5.69879 12.785 5.59968 12.785 5.46341C12.785 5.32713 12.6859 5.22803 12.5497 5.22803H10.679C10.5551 5.22803 10.4436 5.32713 10.4436 5.46341C10.4436 5.59968 10.5427 5.69879 10.679 5.69879Z"
      :fill="gradient ? `url(#${gradientId})` : 'currentColor'" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M11.8806 0C5.31467 0 0 5.32706 0 11.8806C0 18.4341 5.32706 23.7612 11.8806 23.7612C18.4341 23.7612 23.7612 18.4341 23.7612 11.8806C23.7612 5.32706 18.4341 0 11.8806 0ZM16.2785 18.112C16.2785 18.9668 15.5847 19.673 14.7175 19.673H8.48613C7.63132 19.673 6.92518 18.9792 6.92518 18.112V5.66155C6.92518 4.80674 7.61893 4.10059 8.48613 4.10059H14.7175C15.5724 4.10059 16.2785 4.79435 16.2785 5.66155V18.112Z"
      :fill="gradient ? `url(#${gradientId})` : 'currentColor'" />
  </svg>
</template>