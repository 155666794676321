<script setup lang="ts">
const props = defineProps<{
  id: string,
  gradient?: boolean
}>()

const gradientId = `svgGradientTelco-${props.id}`;
</script>

<template>
  <svg width="100%" height="100%" viewBox="0 0 62 62" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient :id="gradientId">
        <stop offset="0%" style="stop-color:#C2E2ED" />
        <stop offset="100%" style="stop-color:#36A4DC" />
      </linearGradient>
    </defs>
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M30.7918 0C13.7925 0 0 13.7806 0 30.7918C0 47.8029 13.7806 61.5835 30.7918 61.5835C47.803 61.5835 61.5835 47.8029 61.5835 30.7918C61.5835 13.7806 47.803 0 30.7918 0ZM30.7918 46.3486C28.2884 46.3486 26.2618 44.322 26.2618 41.8186C26.2618 39.3152 28.2884 37.2768 30.7918 37.2768C33.2952 37.2768 35.3217 39.3033 35.3217 41.8186C35.3217 44.322 33.2952 46.3486 30.7918 46.3486ZM41.2345 33.617C40.3524 34.6184 38.8146 34.7257 37.8132 33.8316C35.8105 32.0673 33.3071 31.1852 30.8037 31.1852C28.3003 31.1852 25.7969 32.0673 23.7942 33.8316C22.7928 34.7138 21.255 34.6184 20.3729 33.617C19.4907 32.6157 19.5861 31.0779 20.5874 30.1957C23.5081 27.6208 27.1559 26.3453 30.8037 26.3453C34.4515 26.3453 38.0993 27.6327 41.0199 30.1957C42.0213 31.0779 42.1286 32.6157 41.2345 33.617ZM47.3499 24.0564C46.5393 25.1174 45.0134 25.3201 43.9525 24.5094C40.0901 21.565 35.4529 20.0987 30.8037 20.0987C26.1545 20.0987 21.5173 21.565 17.6549 24.5094C16.5939 25.3201 15.0681 25.1174 14.2574 24.0564C13.4468 22.9955 13.6495 21.4696 14.7104 20.659C19.455 17.0469 25.1293 15.235 30.8037 15.235C36.4781 15.235 42.1524 17.0469 46.8969 20.659C47.9579 21.4696 48.1606 22.9955 47.3499 24.0564Z"
      :fill="gradient ? `url(#${gradientId})` : 'currentColor'" />
  </svg>
</template>