<script setup lang="ts">
const props = defineProps<{
  id: string,
  gradient?: boolean
}>()

const gradientId = computed(() => `svgGradientEnergy-${props.id}`)
</script>

<template>
  <svg v-if="id" width="100%" height="100%" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient :id="`svgGradientEnergy-${props.id}`">
        <stop offset="0%" style="stop-color:#EE7B20" />
        <stop offset="100%" style="stop-color:#F6B045" />
      </linearGradient>
    </defs>
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M11.5 0C17.8533 0 23 5.14668 23 11.5C23 17.8533 17.8533 23 11.5 23C5.14668 23 0 17.8533 0 11.5C0 5.14668 5.14668 0 11.5 0ZM13.3961 5.4545C13.4823 5.20824 13.2976 5.04818 13.076 5.08512L10.7366 5.47912C10.3796 5.54069 10.2195 5.67612 10.0964 5.98394L7.27677 13.5439C7.19058 13.7778 7.35064 13.9748 7.5969 13.9379L10.3919 13.47L9.97323 18.9245C9.97323 19.1215 10.1702 19.1585 10.2687 19.023L16.3881 9.97323C16.5236 9.76392 16.3881 9.51767 16.0926 9.56692L11.5493 10.3303L13.3715 5.46681L13.3961 5.4545Z"
      :fill="gradient ? `url(#${gradientId})` : 'currentColor'" />
  </svg>
</template>